html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

p {
  margin: 0;
}

:root {
  --light-black: #222222;
  --transparent-black: #1a1a1a65;
  --gray: #a1a1a1;
  --transparent-gray: #d9d9d9;
  --blue: #6cc3c5;
  --white: #ffffff;
  --card-black: rgba(26, 26, 26, 0.65);
  --point-gray: #aaaaaa;
}

* {
  font-family: "Mulish", sans-serif;
}

.App {
  height: 100%;
  background-color: var(--transparent-black);
  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 2;
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-black);
  padding: 0.5rem 1rem;
  height: fit-content;
}

#App-header-logo-container,
#App-header-settings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#App-header-logo-container {
  color: var(--white);
}

#App-header-settings-container {
  column-gap: 5vw;
}

#map-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: var(--light-black);
}

.x-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  column-gap: 0.5rem;
}

/* DATA VIEW CARD */
.card {
  height: calc(100% - 8px);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 8px);
}

.card-container {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  height: calc(100% - 2rem);
  overflow: auto;
  flex: 1;
}

.data-section {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.data-title {
  font-size: 20px;
  margin: 0px;
  color: var(--white);
}

.nor-stat {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 1.5rem;
  align-items: flex-end;
}

.nor-count {
  font-size: 54px;
  font-weight: bold;
  color: white;
  margin: 0;
}

.nor-name {
  font-size: 36px;
  font-weight: bold;
  color: white;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}

.trend {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
}

.nor-trend {
  font-size: 24px;
  margin: 0;
}

.positive-trend {
  color: red;
}

.negative-trend {
  color: green;
}

.neutral-trend {
  color: #ffcd57;
}

.nor-sub {
  font-size: 14px;
  color: var(--gray);
  margin: 0;
}

.dataView-filter {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}

.dd-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  padding: 0 1rem;
}

.dd-toggle > * {
  margin: 0;
  font-size: 16px;
}

.dd-items {
  top: 3rem;
  left: 0;
  position: absolute;
  background-color: var(--card-black);
  border: 1px solid rgb(255, 255, 255, 0.15);
  color: white;
  border-radius: 12px;
  width: 100%;
  padding: 1rem 0;

  row-gap: 0.5rem;
  overflow: auto;
  max-height: 25vh;
  z-index: 1;
}

.dd-item {
  padding: 0 1rem;
}

.rs-legend {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--gray);
  margin-bottom: 1rem;
}

.open-closed {
  column-gap: 0.5rem;
}

.open-closed-itm {
  column-gap: 0.25rem;
}

.progress-bar-container,
.progress-bar {
  width: 100%;
  border-radius: 100px;
  left: 0;
  height: 8px;
  transition: width 0.5s;
}

.progress-bar-container.active {
  background-color: #484848;
}

.progress-bar-container.inactive {
  background-color: #4848488f;
}

.progress-bar {
  background-color: #48d89e;
}

/* POINT DATA CARD */
.react-modal-sheet-backdrop {
  touch-action: auto !important;
  pointer-events: all !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.react-modal-sheet-container {
  background: linear-gradient(
    180deg,
    rgba(239, 239, 239, 0.5),
    0.5%,
    rgba(26, 26, 26, 100%)
  );
  z-index: 3;
  background-color: transparent !important;
}

.vertical-timeline::before {
  width: 1px !important;
}

.vertical-timeline-element-subtitle {
  font-size: 16px !important;
  margin: 0;
}

.react-modal-sheet-header {
  background-color: transparent !important;
}
.react-modal-sheet-drag-indicator {
  color: #aaaaaa;
}
.react-modal-sheet-content {
  background-color: transparent !important;
  /* custom styles */
  overflow: hidden !important;
}

.backDrop {
  font-size: 24px;
  color: white;
  margin: 0;
  font-weight: bold;
}

.backDrop-sub {
  font-size: 14px;
  color: white;
}

.backDrop-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.backDrop-btn {
  border-radius: 50%;
  height: min(10vw, 40px);
  width: min(10vw, 40px);
  background-color: rgb(217, 217, 217, 0.6);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomSheet {
  padding: 0 1rem 1rem 1rem;
  transition: min-height 0.5s;
  display: flex;
  flex-direction: column;
  background-color: transparent !important;
  overflow: hidden;
}

/* SETTINGS CONTAINER */
.searchFilter-container {
  width: calc(100vw - 2rem);
  position: absolute;
  top: 2rem;
  z-index: 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  font-size: 16px;
}

.deselect-btns {
  border: 1px solid var(--blue);
  color: var(--blue);
  border-radius: 20px;
  padding: 4px 16px;
  margin-right: 16px;
  background-color: transparent;
  margin-bottom: 1rem;
}

#filterToggle,
#dataToggle {
  margin: auto;
  border-radius: 50%;
  background-color: var(--card-black);
  border: 1px solid rgb(255, 255, 255, 0.15);
  height: 3rem;
  width: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#searchBar,
#dataView-searchBar,
#data-dd {
  background-color: var(--card-black);
  border: 1px solid rgb(255, 255, 255, 0.15);
  height: 2.5rem;
  color: white;
  border-radius: 12px;
  font-size: 16px;
}

/* FILTER TOGGLE */
#filterToggle {
  padding: 0.5rem;
  color: var(--gray);
}

/* SEARCH BAR */
input:focus {
  outline: none;
}
/* input[type=search] { -webkit-appearance: none; } */

#searchBar,
#dataView-searchBar {
  position: relative;
  flex: 1;
  padding: 0.6rem 0.5rem 0.6rem 2rem;
  background-image: url("./icons/searchIcon.svg");
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.btns-container {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  position: absolute;
  top: 3.5rem;
}

#dataView-searchBar {
  width: 100%;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

/* FILTER */
.filter-container {
  bottom: 8px;
  height: max(40%, fit-content);
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  width: calc(100% - 16px);
}

.filter-section {
  padding: 0.5rem 1rem;
}

.filter-label {
  font-size: 16px;
  color: var(--white);
  margin: 0 0 1rem 0;
}

.inactive-label {
  color: #a1a1a1;
}

.active-label {
  color: var(--white);
}

.filter-container .filter-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
}

.form-check {
  /* color: white !important; */
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  font-size: 14px;
  align-items: center;
}

/* OVERRIDE CHECKBOX STYLING */
.form-check-input:checked {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  appearance: none;
  width: 16px;
  height: 16px;
}

.form-check-input:checked::before {
  content: "\f00c" !important;
  height: 100% !important;
  width: 100% !important;
  font-size: 16px;
  line-height: 0rem;
  color: white;
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
}

.form-check-input {
  border-radius: 0 !important;
  background-color: rgb(256, 256, 256, 0.15) !important;
  border-color: rgb(256, 256, 256, 0.15) !important;
  appearance: none;
  width: 16px;
  height: 16px;
  margin-top: 0;
}

.form-check-input:focus {
  border: none !important;
  box-shadow: 0 0 0 !important;
}

.form-check-label {
  padding-left: 0.5rem;
}

/* DONUT VIZ */
#tooltip {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px;
  border-radius: 2px;
  font-family: sans-serif;
  color: white;
  pointer-events: none;
  box-shadow: 0 0 5px #999999;
}

/* FORM SUBMISSION */
#toggleForm-btn {
  z-index: 1;
  position: absolute;
  bottom: 4vh;
  right: 0.5vh;
  border-radius: 50%;
  border: none;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: var(--blue);
  color: white;
  font-size: 12px;
}

#closeForm-btn {
  background-color: white;
  border-radius: 50%;
  height: 5vh;
  width: 5vh;
  border: none;
  color: black;
}

#closeLoader-btn {
  background-color: transparent;
  color: white;
  height: 5vh;
  width: 5vh;
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

#form-container {
  color: var(--white);
}

/* input[type="file"] {
  display: none;
} */

input[type="text"],
input[type="email"] {
  padding: 0 1rem;
  background-color: var(--card-black);
  border: 1px solid rgb(255, 255, 255, 0.15);
  height: 2.5rem;
  color: white;
  border-radius: 12px;
  font-size: 16px;
  width: 100%;
}

#submitForm-btn {
  width: fit-content;
  border-radius: 30px;
  padding: 1rem 2rem;
  color: var(--light-black);
  background-color: var(--white);
  border: none;
}

input[type="file"] {
  color: white;
  font-family: "Mulish", sans-serif;
}

input::file-selector-button,
#geolocate-btn {
  width: fit-content;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  color: var(--light-black);
  background-color: var(--white);
  border: none;
  font-size: 12px;
}

/* CAROUSEL */

#image-carousel {
  padding: 1.5rem 0;
  position: relative;
}

.carousel.slide {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: calc(100vw - 16px - 4rem);
  overflow: auto;
}

.carousel-inner {
  padding-bottom: calc(2rem + 10px);
}

.carousel-indicators {
  max-width: 5rem;
  display: flex !important;
  column-gap: 1rem !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  margin: auto;
}

.carousel-indicators .active {
  background-color: var(--point-gray) !important;
}

#image-carousel .carousel-indicators [data-bs-target] {
  /* border-radius: 50%; */
  height: 10px !important;
  width: 10px !important;
  opacity: 1 !important;
  border: none !important;
  transition-timing-function: ease-in;
}

#demographic-slider .carousel-indicators .active {
  background-color: #000000 !important;
}

.slider-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* LOADER */

.loadingWheel-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: var(--card-black);
  row-gap: 1rem;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 100%;
  width: 100%;
  padding: 4rem;
  text-align: center;
  backdrop-filter: blur(60px);
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid var(--blue); /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  color: white;
  font-size: 24px;
}

.loader-heading {
  color: white;
  font-weight: bolder;
  font-size: 32px;
}

/* CHATBOT */
.chatbot-container {
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 2rem;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chatbot-container::-webkit-scrollbar {
  display: none;
}

.messages {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.messages-container::-webkit-scrollbar {
  display: none;
}

.message-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.message-row.bot {
  justify-content: flex-start;
}

.message-row.user {
  justify-content: flex-end;
}

.message-container {
  background-color: var(--gray);
  padding: 1rem;
  border-radius: 20px;
  width: fit-content;
  max-width: 80%;
  overflow-x: hidden;
}

.message-container.bot {
  background-color: var(--gray);
}

.message-container.user {
  background-color: var(--blue);
}

.response {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.send-message {
  background-color: transparent;
  color: white;
  border: 1px solid var(--gray);
}

/* REACT + COMMENT */

.comment-section {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 1rem;
  max-height: 60vh;
  overflow: auto;
}

.comment-section::-webkit-scrollbar {
  display: none;
}

.comment-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.comment {
  border-radius: 30px;
  color: white;
  background-color: rgba(117, 117, 117, 0.49);
  padding: 0.5rem 1rem;
}

.comment-time {
  color: var(--gray);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 2rem;
  font-size: 14px;
}

.reactComment-container {
  color: white;
  padding: 1rem;
  border-top: 0.5px solid var(--transparent-gray);
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  align-items: center;
}

.comment-container,
.react-container,
.share-btn {
  background-color: rgb(256, 256, 256, 0.1);
  border-radius: 30px;
  padding: 0.25rem 0.75rem;
}

.comment-container {
  transition: flex 0.5s;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}

.comment-info {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
}

#comment-input {
  background-color: transparent;
  border: none;
  color: white;
  width: 100%;
}

.react-container {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.emoji-container {
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
}

.share-btn {
}

/* SPLASH PAGE */
#splash-page {
  height: 100vh;
  width: 100vw;
  background-color: var(--light-black);
  position: fixed;
  z-index: 1000;
  top: 0;
  display: flex;
  flex-direction: column;
}

.splash-logo {
  height: 25vh;
  width: 25vh;
}

.splash-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.75rem;
  justify-content: flex-start;
}

.splash-heading {
  font-size: 36px;
  color: white;
}

.splash-logo-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 55vh;
}

#splash-bot,
#splash-viz {
  border-radius: 15px;
  padding: 1rem 2rem;
  color: white;
  width: 250px;
  border: none;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* max-width: 300px; */
}

#splash-bot {
  background-color: rgb(255, 255, 255, 0.15);
}

#splash-viz {
  background-color: var(--blue);
}

/* SUBSCRIBE */
.subscribe-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: var(--card-black);
  row-gap: 1rem;
  border-radius: 20px;
  height: fit-content;
  width: 100%;
  backdrop-filter: blur(60px);
  font-size: 16px;
  padding: 1.5rem;
  top: 60px;
  color: white;
  max-width: 600px;
  border: 1px solid rgb(255, 255, 255, .5) !important;
}

.subscribe-container input {
  background-color: white;
  color: black;
}

.subscription-page {
  color: white;
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  overflow: auto;
  z-index: 100;
  background-color: var(--light-black);
}


.sub-list {
  display: flex;
  flex-direction: column;
}

.sub-link {
  color: white;
  cursor: pointer;
}

.sub-link:hover {
  color: var(--blue);
}




/* UTIl */

.row-btn-container {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.primary-btn-blue, .primary-btn-gray {
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  color: white;
}

.primary-btn-blue {
  background-color: var(--blue);

}

.primary-btn-gray {
  background-color: rgb(255, 255, 255, 0.15);

}


#data-dd {
  position: relative;
}

.bold {
  font-weight: bold;
}

hr {
  border: none;
  width: calc(100% - 2rem);
  height: 1px;
  background-color: #4e4d4d;
}

.dd-items > hr {
  border: none;
  height: 1px !important;
  background-color: var(--transparent-gray);
  margin: 0;
  align-self: stretch;
  margin: 1rem;
}

#data-container #data-dd {
  width: 60%;
  position: relative;
}

.card-style {
  position: absolute;
  border-radius: 20px;
  background-color: var(--card-black);
  backdrop-filter: blur(60px);
}

.font-16 {
  font-size: 16px;
  color: var(--white);
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.5rem;
}

.flexCol-sm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 0.5rem;
}

.vr {
  height: 20px;
  background-color: var(--point-gray);
  width: 1px;
}
